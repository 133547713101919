/* Import Google Font if needed globally (e.g., in index.css or App.css) */
/* @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap'); */

/* --- Studio Container --- */
.studio-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1e1e1e;
  color: #d4d4d4;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
}

.loading-indicator,
.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ddd;
  font-size: 1.2em;
}

.error-message {
  color: #ff6666;
}

/* --- Header --- */
.studio-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background-color: #000;
  /* Dark black header */
  border-bottom: 1px solid #444444;
  flex-shrink: 0;
  height: 40px;
  box-sizing: border-box;
}

.studio-header-left,
.studio-header-center,
.studio-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.studio-logo {
  height: 25px;
  /* Adjust logo size as needed */
  margin-right: 10px;
}

.studio-title {
  font-weight: bold;
  color: #cccccc;
  font-size: 0.9em;
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.studio-icon {
  cursor: pointer;
  opacity: 0.8;
  font-size: 1.1em;
}

.studio-icon:hover {
  opacity: 1;
}

/* --- Create Button in Header --- */
.studio-header-right .studio-create-button {
  background: none;
  border: none;
  color: #ccc;
  cursor: pointer;
  padding: 0;
  font-size: 1.2em;
  opacity: 0.8;
}

.studio-header-right .studio-create-button:hover {
  opacity: 1;
}

/* --- Tabs --- */
.studio-tab {
  background-color: #333;
  /* Dark grey tabs */
  border: 1px solid #3f3f3f;
  color: #fff;
  /* White text for inactive tabs */
  padding: 5px 15px;
  cursor: pointer;
  font-size: 0.85em;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
  display: flex;
  /* Enable flex layout for icon and text */
  align-items: center;
  /* Vertically align icon and text */
  justify-content: center;
  /* Center content horizontally */
  gap: 5px;
  /* Space between icon and text */
}

.studio-tab.active {
  background-color: orange;
  /* Orange active tab */
  color: #000;
  /* Black text for active tab */
  border-color: orange;
  border-bottom: 1px solid orange;
  z-index: 3;
}

.studio-tab:hover:not(.active) {
  background-color: #444;
  /* Slightly lighter grey on hover */
}

.studio-tab .tab-checkmark {
  display: inline-block;
  margin-left: 5px;
  /* Adjust spacing as needed */
}

.studio-tab .tab-checkmark svg {
  width: 0.7em;
  /* Adjust size of checkmark */
  height: 0.7em;
  vertical-align: middle;
}

/* --- Main Content Area --- */
.studio-main-content {
  flex-grow: 1;
  display: flex;
  background-color: #1e1e1e;
  overflow: hidden;
  position: relative;
  transition: height 0.3s ease-in-out;
  z-index: 2;
}

/* Adjust height based on console state */
.studio-main-content.console-open {
  height: calc(100vh - 40px - 200px);
}

.studio-main-content.console-maximized {
  height: 50px;
  overflow: hidden;
}

/* --- Spaces Tab Content --- */
.spaces-tab-content {
  flex-grow: 1;
  padding: 8px;
  /* Add padding around Spaces content */
  overflow-y: auto;
  /* Enable scroll if needed */
}

.spaces-tab-content .spaces-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #444;
}

.spaces-tab-content .spaces-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #e0e0e0;
  /* Ensure title text color is visible */
}

.spaces-tab-content .feed-list-container {
  /* display: flex; - Removed for grid */
  /* flex-direction: column; - Removed for grid */
  gap: 5px;
  overflow-y: auto;
  display: grid;
  /* Enable grid layout */
  grid-template-columns: repeat(4, minmax(0, 1fr)); /* Changed to 2 columns */
  /* 2 columns, adjust minmax as needed */
  gap: 15px;
  /* Adjust gap between grid items */
  justify-content: center; /* Center the grid items horizontally */
}


/* --- Code Editor --- */
.studio-code-editor {
  display: flex;
  flex-grow: 1;
  font-family: 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;
  position: relative; /* To contain saving indicator */
}

/* --- Preview Area --- */
.studio-preview-area {
  flex-grow: 1;
  padding: 0;
  /* Remove padding from preview area */
  background-color: #ffffff;
  color: #333333;
  overflow: hidden;
  /* Hide scrollbars in iframe container */
  height: 100%;
  box-sizing: border-box;
  display: flex;
  /* Center content if no iframe is loaded */
  align-items: center;
  justify-content: center;
}

.studio-preview-area iframe {
  border: none;
  /* Remove iframe border */
  width: 100%;
  /* Iframe takes full width */
  height: 100%;
  /* Iframe takes full height */
  display: block;
  /* Ensure iframe takes full space */
}


/* --- Console --- */
.studio-console {
  background-color: #1e1e1e;
  border-top: 1px solid #444444;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 200px;
  transition: height 0.3s ease-in-out;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
}

.studio-console.maximized {
  height: calc(100vh - 40px);
}

.studio-console-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  background-color: #333333;
  font-size: 0.8em;
  height: 25px;
  box-sizing: border-box;
}

.studio-console-header span {
  color: #ccc;
}

.studio-console-icons {
  display: flex;
  gap: 5px;
}

.studio-console-icon-btn {
  background: none;
  border: none;
  color: #ccc;
  cursor: pointer;
  padding: 0 3px;
}

.studio-console-icon-btn .studio-icon {
  font-size: 0.9em;
  opacity: 0.7;
}

.studio-console-icon-btn:hover .studio-icon {
  opacity: 1;
}

.studio-console-content {
  flex-grow: 1;
  padding: 5px 10px;
  overflow: auto;
  font-family: 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
  font-size: 0.85em;
  color: #cccccc;
  line-height: 1.4;
  height: calc(100% - 25px);
  box-sizing: border-box;
}

.studio-console-content pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
}

/* --- Button to re-open console --- */
.studio-open-console-btn {
  position: fixed;
  bottom: 5px;
  left: 10px;
  z-index: 15;
  background-color: #4a4a4a;
  color: #ddd;
  border: 1px solid #666;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8em;
}

.studio-open-console-btn:hover {
  background-color: #5a5a5a;
}

/* --- Specific Color Mimicry for Code (Simple) --- */
/* ... (rest of code color styles - no changes here) ... */

/* Modal Styles - Reused from Spaces.css to ensure consistency in Studio */
.create-workspace-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #333;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #555;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  position: relative;
}

.modal-close-button {
  color: #aaa;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal-close-button:hover,
.modal-close-button:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.modal-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #444;
  color: #e0e0e0;
  box-sizing: border-box;
}

.modal-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.modal-confirm-button,
.modal-cancel-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.modal-confirm-button {
  background-color: #28a745;
  /* Green confirm button */
  color: white;
}

.modal-cancel-button {
  background-color: #dc3545;
  /* Red cancel button */
  color: white;
}

.modal-confirm-button:hover {
  background-color: #218838;
}

.modal-cancel-button:hover {
  background-color: #c82333;
}

/* --- Reused Styles from Spaces.css --- */
.feed-list-container {
  /* Reused in Studio for Spaces Tab */
  /* display: flex; - Removed for grid layout */
  /* flex-direction: column; - Removed for grid layout */
  gap: 10px;
  overflow-y: auto;
}

.feed-card {
  /* Reused in Studio for Spaces Tab */
  background-color: #333333;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
  width: calc(50% - 15px); /* Adjusted width to account for gap - Wider cards */
  box-sizing: border-box; /* Ensure padding is within the width */
}


.feed-card:hover {
  background-color: #444444;
}

.feed-actions {
  /* Reused in Studio for Spaces Tab */
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  gap: 5px;
}

.feed-card:hover .feed-actions {
  display: flex;
}

.feed-edit-icon,
.feed-delete-icon {
  /* Reused in Studio for Spaces Tab */
  color: #ccc;
  cursor: pointer;
  font-size: 0.9em;
  padding: 3px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.3);
}

.feed-edit-icon:hover,
.feed-delete-icon:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.feed-delete-icon {
  color: #ff6666;
}

.feed-card-content {
  /* Reused in Studio for Spaces Tab */
  margin-bottom: 10px;
}

.feed-card-content.edit-mode {
  margin-bottom: 0;
}

.feed-card-name {
  /* Reused in Studio for Spaces Tab */
  font-size: 1.1em;
  font-weight: bold;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.feed-card-name-input {
  /* Reused in Studio for Spaces Tab */
  width: calc(100% - 10px);
  padding: 8px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #444;
  color: #e0e0e0;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.feed-date {
  /* Reused in Studio for Spaces Tab */
  font-size: 0.85em;
  color: #999;
}

.anonymous-card,
.add-workspace-card {
  /* Reused in Studio for Spaces Tab */
  background-color: #333333;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.anonymous-card .feed-card-title {
  /* Reused in Studio for Spaces Tab */
  font-size: 1.4em;
  margin-bottom: 10px;
}

.anonymous-card .feed-card-description,
.add-workspace-card .feed-card-description {
  /* Reused in Studio for Spaces Tab */
  margin-bottom: 20px;
  color: #ccc;
}

.feed-button-container {
  /* Reused in Studio for Spaces Tab */
  display: flex;
  justify-content: center;
  gap: 15px;
}

.feed-card-button {
  /* Reused in Studio for Spaces Tab */
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.signup-button {
  /* Reused in Studio for Spaces Tab */
  background-color: #007bff;
  color: white;
}

.login-button {
  /* Reused in Studio for Spaces Tab */
  background-color: #6c757d;
  color: white;
}

.signup-button:hover {
  /* Reused in Studio for Spaces Tab */
  background-color: #0056b3;
}

.login-button:hover {
  /* Reused in Studio for Spaces Tab */
  background-color: #545b62;
}

.neo-orange-text {
  /* Reused in Studio for Spaces Tab */
  color: #ff7f00;
}

.loading-indicator {
  /* Reused in Studio for Spaces Tab */
  text-align: center;
  padding: 20px;
  color: #aaa;
}

.error-message {
  /* Reused in Studio for Spaces Tab */
  text-align: center;
  padding: 20px;
  color: #ff6666;
}

/* --- Version Navigation --- */
.version-nav {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ccc;
  font-size: 0.9em;
}

.version-nav-button {
  background: none;
  border: none;
  color: #ccc;
  cursor: pointer;
  opacity: 0.6;
  font-size: 1em;
  padding: 0;
  /* No padding for icon buttons */
}

.version-nav-button:hover {
  opacity: 1;
}

.version-nav-button:disabled {
  opacity: 0.3;
  cursor: default;
}

.version-number {
  min-width: 80px;
  /* Ensure version number doesn't jump layout */
  text-align: center;
}

/* Monaco Editor Styles - Ensure it fits in the studio-code-editor container */
.studio-code-editor .monaco-editor {
    height: 100%;
}