/* Analytics.css */
.analytics-page {
  background-color: black;
  color: #ff7f00;
  min-height: 100vh;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.analytics-header {
  width: 100%;
  background-color: #111;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.analytics-header-left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.analytics-header-right {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end; /* Align items to the right */
  width: auto; /* Adjust width to content */
}

.analytics-header-right h1 {
  margin: 0 15px 0 0; /* Add right margin to title */
  font-size: 1.5em; /* Adjust font size as needed */
}


.analytics-logo {
  height: 40px;
}

.analytics-close-button,
.preset-date-button,
.date-go-button,
.compare-button,
.chart-type-select,
.analytics-action-button,
.sku-dropdown,
.earnings-button,
.analytics-refresh-button,
.preset-date-dropdown,
.earnings-option-button,
.earnings-portal-button {
  background: none;
  color: #ff7f00;
  border: 2px solid #ff7f00;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 5px;
}

.analytics-close-button:hover,
.preset-date-button:hover,
.date-go-button:hover,
.compare-button:hover,
.chart-type-select:hover,
.analytics-action-button:hover,
.sku-dropdown:hover,
.earnings-button:hover,
.analytics-refresh-button:hover,
.preset-date-dropdown:hover,
.analytics-close-button.active,
.preset-date-button.active,
.preset-date-button.active,
.date-go-button.active,
.compare-button.active,
.chart-type-select.active,
.analytics-action-button.active,
.sku-dropdown.active,
.earnings-button.active,
.analytics-refresh-button.active,
.preset-date-dropdown.active,
.earnings-option-button:hover,
.earnings-portal-button:hover {
  background-color: #ff7f00;
  color: black;
}

.preset-date-button.active {
  background-color: #ff7f00;
  color: black;
}

.analytics-refresh-button {
  margin-right: 0;
  padding: 8px 10px;
  border: none;
  border: 2px solid #ff7f00;
  border-radius: 5px;
}

.earnings-button {
  margin-right: 0;
  padding: 8px 10px;
  border: none;
  border: 2px solid #ff7f00;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}


.analytics-container {
  width: 95%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sku-refresh-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}


.sku-dropdown-container {
  display: flex;
  align-items: center;
}


.sku-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="orange" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: black;
  color: #ff7f00;
  border: 2px solid #ff7f00;
  width: 70%; /* Reduced width by 30% */
  max-width: 200px; /* Example max-width to prevent too narrow dropdown */
}
.sku-dropdown:hover, .sku-dropdown.active {
  background-color: #ff7f00;
  color: black;
}


.analytics-summary-header {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.analytics-date-range {
  font-size: 1.1em;
  font-weight: bold;
  justify-content: center; /* Changed to center to horizontally center content */
  text-align: center;
}


.analytics-summary-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.analytics-card {
  background-color: #222;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #333;
}

.analytics-card:hover {
  border-color: #ff7f00;
}

.analytics-card span {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #ddd;
}

.analytics-card p {
  font-size: 1.5em;
  margin: 0;
}

.analytics-date-controls {
  display: flex;
  flex-direction: row; /* Changed to row */
  gap: 10px;
  justify-content: center; /* Changed to center to horizontally center content */
  margin-bottom: 20px;
  align-items: center; /* Align items vertically in row */
  width: 100%;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.preset-date-ranges-dropdown {
  /* Already styled, no position change needed */
}

.preset-date-dropdown {
  margin-right: 10px; /* Add some right margin to separate from date inputs */
  width: auto; /* Adjust width as needed */
}
.preset-date-dropdown:hover, .preset-date-dropdown.active {
  background-color: #ff7f00;
  color: black;
}


.preset-date-ranges { /* Hide the old button-based preset ranges */
  display: none;
}

.date-display-group {
  display: flex; /* Flex to align date inputs and button in a row */
  gap: 10px;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping */
}


.date-input-group {
  display: flex;
  gap: 5px; /* Reduced gap between label and input */
  align-items: center;
  justify-content: flex-start; /* Align content to the start */
}

.date-input-group label {
  margin-right: 0; /* Removed margin from label */
}

.date-input-group input[type="date"] {
  background-color: #333;
  color: #ff7f00;
  border: 1px solid #555;
  padding: 8px;
  border-radius: 4px;
  width: auto;
}

.date-go-button {
  padding: 8px 12px;
}

.date-go-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #333;
  color: #777;
  border-color: #555;
}

.analytics-action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.analytics-action-button {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.chart-header h3 {
  margin: 0;
  font-size: 1.2em;
}

.chart-header-right {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.compare-button {
  padding: 8px 12px;
}

.chart-type-dropdown {
  display: flex;
  gap: 5px;
}

.chart-type-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="orange" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: black;
  color: #ff7f00;
  border: 2px solid #ff7f00;
  width: auto;
}
.chart-type-select:hover, .chart-type-select.active {
  background-color: #ff7f00;
  color: black;
}


.chart-type-buttons { /* Hide old text buttons */
  display: none;
}

.chart-type-buttons button {
  padding: 8px 12px;
}

.analytics-chart {
  background-color: #111;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #333;
  position: relative;
  width: 100%;
  min-height: 300px; /* Ensure chart area has some height for spinner to be centered */
  display: flex; /* Enable flexbox for centering spinner */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.chart-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.chart-no-data {
  color: #ff7f00;
  padding: 20px;
  text-align: center;
  font-size: 1em;
}

.analytics-loading {
  text-align: center;
  padding: 50px;
  fontSize: 1.2em;
  color: #ff7f00;
  minHeight: 'calc(100vh - 0px)';
  display: flex;
  alignItems: center;
  justifyContent: center;
  backgroundColor: 'black';
}

/* Earnings Modal Styles */
.earnings-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.earnings-modal {
  background-color: black;
  color: #ff7f00;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid #ff7f00;
  width: 80%;
  max-width: 500px;
}

.earnings-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #ff7f00;
  padding-bottom: 10px;
}

.earnings-modal-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.earnings-modal-close-button {
  background: none;
  color: #ff7f00;
  border: 2px solid #ff7f00;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.3s, color 0.3s;
}

.earnings-modal-close-button:hover {
  background-color: #ff7f00;
  color: black;
}

.earnings-modal-body p {
  margin: 0;
  font-size: 1em;
  line-height: 1.6;
}

.earnings-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.earnings-option-button {
  flex: 1;
  margin: 0 10px;
}
.earnings-option-button-bank {
  background-color: darkgreen;
  color: white;
  border-color: darkgreen;
}

.earnings-option-button-paypal {
  background-color: darkblue;
  color: white;
  border-color: darkblue;
}


.earnings-applying-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.earnings-portal-button {
  margin-top: 20px;
  width: 100%;
}