.console-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden; /* Ensure content doesn't overflow container */
}

.chat-display {
    flex-grow: 1;
    overflow-y: auto; /* Scrollable chat history */
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.message-container {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack query and response */
    align-items: flex-start; /* Default alignment for messages */
}

.message {
    padding: 8px 12px;
    border-radius: 15px;
    margin-bottom: 3px;
    word-wrap: break-word;
    max-width: 80%; /* Prevent messages from taking full width */
}

.message.query {
    background-color: #444; /* User query background */
    color: #fff;
    align-self: flex-end; /* Align user queries to the right */
}

.message.response {
    background-color: #333; /* Response background */
    color: #eee;
    align-self: flex-start; /* Align responses to the left */
}
.message.response.error-message {
    background-color: #5a2d2d; /* Darker red for error responses */
    color: #ffdddd; /* Lighter red text for error */
}
.response-align {
    align-items: flex-start; /* Align items in response direction to start (left) */
}

.query-align {
    align-items: flex-end;     /* Align items in query direction to end (right) */
}

.typing-indicator {
    display: flex;
    align-items: center;
}

.typing-dots {
    display: inline-flex;
    margin-left: 5px;
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #aaa;
    margin-right: 3px;
    animation: typing 1.5s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

@keyframes typing {
    0% { opacity: 0.4; }
    50% { opacity: 1; }
    100% { opacity: 0.4; }
}


.input-area {
    display: flex;
    padding: 10px;
    border-top: 1px solid #444;
}

.message-input {
    flex-grow: 1;
    padding: 8px;
    border-radius: 15px;
    border: 1px solid #555;
    background-color: #2a2a2a; /* Darker input background */
    color: #fff;
    resize: none; /* Prevent manual resizing */
    height: 30px; /* Fixed height */
    overflow-y: hidden; /* Hide vertical scrollbar */
    line-height: 1.5; /* Adjust line height for text alignment */
    transition: height 0.2s ease; /* Smooth height transition */
    min-height: 30px; /* Minimum height to prevent collapsing */
    max-height: 150px; /* Maximum height before scrolling */
}

.message-input.typing-active {
    border-color: #777; /* Slightly highlight border when typing */
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.3); /* Optional subtle shadow */
}


.send-button {
    background: none;
    border: none;
    color: #fff;
    padding: 0 15px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.send-button:hover, .send-button:focus {
    opacity: 1;
}
.send-button:disabled {
    opacity: 0.3;
    cursor: default;
}

.send-button svg {
    fill: #ff914d; /* Neon orange color for send icon */
}

.embed-preview {
    margin-top: 10px;
    border: 1px solid #555;
    border-radius: 8px;
    overflow: hidden; /* Clip iframe content to border */
}